import { useMediaQuery } from '@mui/material';
import { PayPalMessages, PayPalScriptProvider } from '@paypal/react-paypal-js';

import { theme } from '../../../theme';

import { StyledContainer } from './PayPalMessage.styled';
import { PayPalMessageProperties } from './PayPalMessage.types';

export const PayPalMessage = ({
    clientId,
    amount,
    style,
}: PayPalMessageProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const defaultTextAlign = isDesktop ? 'center' : 'left';
    const defaultTextSize = 13;

    return (
        <StyledContainer data-testid="PayPalMessage">
            <PayPalScriptProvider
                options={{
                    'client-id': clientId,
                    'disable-funding': 'credit,card',
                    'enable-funding': 'paylater',
                    currency: 'GBP',
                    components: 'messages',
                }}
            >
                <PayPalMessages
                    style={{
                        layout: 'text',
                        logo: {
                            type: 'alternative',
                            position: 'left',
                        },
                        text: {
                            size: style?.textSize ?? defaultTextSize,
                            align: style?.textAlign ?? defaultTextAlign,
                        },
                    }}
                    amount={amount}
                    forceReRender={[amount, isDesktop]}
                />
            </PayPalScriptProvider>
        </StyledContainer>
    );
};
