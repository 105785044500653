import { TggMembershipType, ProductVariant } from '@tgg/common-types';

import { MembershipTypeText } from './membershipDetails.constant';

export function getMembershipType(membership: string): string {
    if (membership.includes(ProductVariant.Student)) {
        return MembershipTypeText.STUDENT;
    }
    if (
        membership.toLowerCase().includes('off') &&
        membership.toLowerCase().includes('peak')
    ) {
        return MembershipTypeText.OFF_PEAK;
    }
    if (membership.toLowerCase().includes('standard')) {
        return MembershipTypeText.STANDARD;
    }
    if (membership.toLowerCase().includes('ultimate')) {
        return MembershipTypeText.ULTIMATE;
    }
    if (membership.includes(ProductVariant.DayPass)) {
        return MembershipTypeText.DAY_PASS;
    }
    if (membership.includes(TggMembershipType.Live_It_Fixed)) {
        return MembershipTypeText.LIVE_IT_FIXED;
    }
    if (membership.includes(ProductVariant.GuestPass)) {
        return MembershipTypeText.GUEST_PASS;
    }

    switch (membership) {
        case TggMembershipType.Staff:
        case 'StaffMembership': {
            return MembershipTypeText.STAFF;
        }
        case TggMembershipType.Do_It:
        case 'Do_it':
        case 'Complimentary_DO_IT': {
            return MembershipTypeText.DO_IT;
        }

        case TggMembershipType.Live_It:
        case 'Live_It ':
        case 'Live_it':
        case 'Complimentary_LIVE_IT': {
            return MembershipTypeText.LIVE_IT;
        }
        default: {
            return '';
        }
    }
}

// fn to check if user has fixed-term membership like StandardFixed6, etc.
export const checkFixedTermMembership = (membership: string) => {
    return !!(
        (/\d+/.test(membership) &&
            membership.toLowerCase().includes('offpeak')) ||
        (/\d+/.test(membership) &&
            membership.toLowerCase().includes('standard')) ||
        (/\d+/.test(membership) &&
            membership.toLowerCase().includes('ultimate')) ||
        membership.includes(TggMembershipType.Live_It_Fixed)
    );
};

export function getMembershipDescription(membership: string): string {
    const membershipType = getMembershipType(membership) || '';
    return `${membershipType.toLowerCase()} membership`;
}

const ultimateMemberships = new Set([
    TggMembershipType.UltimateFixed12,
    TggMembershipType.UltimateFixed9,
    TggMembershipType.UltimateFixed6,
    TggMembershipType.UltimateFixed3,
    TggMembershipType.UltimateMonthly,
]);

const dayPassMemberships = new Set([
    TggMembershipType.DayPass,
    TggMembershipType.DayPass3,
    TggMembershipType.DayPass5,
]);

const monthlyMemberships = new Set([
    TggMembershipType.Live_It,
    TggMembershipType.Do_It,
    TggMembershipType.UltimateMonthly,
    TggMembershipType.StandardMonthly,
    TggMembershipType.OffPeakMonthly,
]);

export const isUltimateMembership = (membershipType: TggMembershipType) => {
    return ultimateMemberships.has(membershipType);
};

export const isDayPassMembership = (membershipType?: TggMembershipType) => {
    return membershipType && dayPassMemberships.has(membershipType);
};

export const isMonthlyMembership = (membershipType: TggMembershipType) => {
    return monthlyMemberships.has(membershipType);
};
