import { useMemo } from 'react';

import { PromotionalLinkItem } from '../../components/PromotionalLink/PromotionalLink.types';
import { MainMenuNavigationLink } from '../../components/SideNavigation';
import { useFlagsContext } from '../flagsContext';

import { MenuItemsProviderProperties } from './menuItemContext.types';
import { MenuItemsContext } from './menuItemsContext';
import { commonMenuItems } from './menuItemsDefaults';

export function MenuItemsProvider({
    children,
    menuItems,
}: MenuItemsProviderProperties) {
    const {
        flags: {
            support_centre_enabled: { enabled: enabledSupportCentre },
            rejoin_journey: { enabled: rejoinJourneyEnabled },
        },
    } = useFlagsContext();
    const contextValue = useMemo(() => {
        const menu = { ...commonMenuItems, ...menuItems };
        const mainMenuWithFlags = menu.mainMenu
            .filter(({ text }: MainMenuNavigationLink) =>
                rejoinJourneyEnabled
                    ? text !== 'FAQs' && text !== 'Careers'
                    : text,
            )
            .filter(({ text }: MainMenuNavigationLink) =>
                !enabledSupportCentre ? text !== 'help & support' : text,
            );
        const promoLinksWithRejoinFlag = menu.promotionalLinks.filter(
            ({ promotionalLinkText }: PromotionalLinkItem) =>
                rejoinJourneyEnabled
                    ? promotionalLinkText !== 'Forgotten PIN?'
                    : promotionalLinkText,
        );
        if (rejoinJourneyEnabled) {
            promoLinksWithRejoinFlag.reverse();
        }

        const flaggedMenuItems = {
            ...menu,
            myAccount: rejoinJourneyEnabled ? menu.myAccount : undefined,
            mainMenu: mainMenuWithFlags,
            promotionalLinks: promoLinksWithRejoinFlag,
        };
        return { menuItems: flaggedMenuItems };
    }, [enabledSupportCentre, menuItems, rejoinJourneyEnabled]);
    return (
        <MenuItemsContext.Provider value={contextValue}>
            {children}
        </MenuItemsContext.Provider>
    );
}
