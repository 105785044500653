import { OptimizelyFeatureFlags } from '@tgg/common-types';

import { TggFlagsObject } from './featureFlags.types';

export const defaultFlags: TggFlagsObject = {
    [OptimizelyFeatureFlags.CLEAR_CACHE_ON_FLAGS_CHANGE]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.HEADLINE_RIBBON_ENABLED]: {
        enabled: true,
    },
    [OptimizelyFeatureFlags.JJ_ADVISORY_MESSAGE_ENABLED]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.REJOIN_JOURNEY]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.MEMBERSHIP_TERMS_VISIBILITY]: {
        enabled: false,
        variationKey: 'Control',
    },
    [OptimizelyFeatureFlags.SHORTEN_MBT_PRICE_DRAW_TEST]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.PAYMENT_IN_ARREARS]: { enabled: false },
    [OptimizelyFeatureFlags.PURGE_ALL_CACHE_TRIGGER]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.PURGE_CACHE_BY_AMPLIENCE_TEST_FLAG]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.PURGE_CACHE_BY_URL_TEST_FLAG]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.RENEWALS_ENABLED]: { enabled: false },
    [OptimizelyFeatureFlags.RETURN_USER_BASKET]: { enabled: false },
    [OptimizelyFeatureFlags.SUPPORT_CENTRE_ENABLED]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.AA_TEST]: {
        enabled: false,
        variationKey: 'FXAA_control',
        variables: {
            enableDataLayer: false,
        },
    },
    [OptimizelyFeatureFlags.GUEST_PASS_ENABLED]: { enabled: false },
    [OptimizelyFeatureFlags.GUEST_PASS_SLOTS_USAGE_ENABLED]: { enabled: false },
    [OptimizelyFeatureFlags.RENEWAL_UPSELL_ENABLED]: { enabled: false },
    [OptimizelyFeatureFlags.MULTIPLE_ADD_ONS_ENABLED]: { enabled: false },
    [OptimizelyFeatureFlags.SAVINGS_VISIBILITY_VARIATIONS]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.YOUR_PAYMENTS_BUTTON_VARIATIONS]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.API_VERSION_3_ENABLED]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.USE_OPTIMIZELY_REACT_SDK]: {
        enabled: true,
        variationKey: 'proxy',
    },
    [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_PRODUCT_OPTIONS]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_TABLE_IFRAME]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.PRODUCT_TABLE_FREE_FREEZE_NOTICE]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_GYM_BAG_IFRAME]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.PINO_TABLE_MBT_OPTIMIZATION]: {
        enabled: false,
        variationKey: 'control',
    },
};
