import Link from 'next/link';

import { getTestId, isSupportCentre } from '@tgg/util';

import { Palette, palette } from '../../theme';
import { Chip } from '../Chip';
import { Icon } from '../Icon';

import {
    StyledText,
    StyledListItem,
    StyledContainer,
    StyledHorizontalRule,
    StyledSubMenuText,
    StyledLinkContainer,
} from './NavigationItem.styled';
import { NavigationItemProperties } from './NavigationItem.types';

/**
 * Use the `NavigationItem` component as an Item in the Navigation.
 */
export function NavigationItem({
    isSubMenuAlternativeItem = false,
    text,
    leftIconName,
    divider = true,
    onClick,
    href,
    tabIndex,
    caseSensitive = false,
    testId,
    itemTestId,
    chipText,
    chipColor,
}: NavigationItemProperties) {
    if (href && leftIconName) {
        const isSupportCentreLink = isSupportCentre(href);
        return (
            <StyledListItem>
                <StyledLinkContainer
                    href={href}
                    $leftIconExists
                    {...(onClick && {
                        onClick: () => onClick(),
                    })}
                    tabIndex={tabIndex}
                    rel={isSupportCentreLink ? 'noopener noreferrer' : ''}
                >
                    <Icon
                        name={leftIconName}
                        color={palette.primary.main as Palette}
                    />

                    {isSubMenuAlternativeItem ? (
                        <StyledSubMenuText
                            $caseSensitive={caseSensitive}
                            $isSubMenuAlternativeItem
                        >
                            {text}
                        </StyledSubMenuText>
                    ) : (
                        <StyledText $caseSensitive={caseSensitive}>
                            {text}
                            <span>
                                {chipText && chipColor && (
                                    <Chip text={chipText} tint={chipColor} />
                                )}
                            </span>
                        </StyledText>
                    )}
                </StyledLinkContainer>
                {divider && <StyledHorizontalRule />}
            </StyledListItem>
        );
    }
    if (onClick && leftIconName) {
        return (
            <StyledListItem>
                <StyledContainer
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        event.preventDefault();
                        onClick();
                    }}
                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            onClick();
                        }
                    }}
                    tabIndex={tabIndex}
                    role="button"
                    aria-pressed={false}
                    data-testid={getTestId(`${testId}-section`)}
                >
                    <Icon
                        name={leftIconName}
                        color={palette.primary.main as Palette}
                    />

                    <StyledText $caseSensitive={caseSensitive}>
                        {text}
                    </StyledText>

                    <Icon
                        name="chevronRight"
                        size={22}
                        color={palette.primary.main as Palette}
                    />
                </StyledContainer>
                {divider && <StyledHorizontalRule />}
            </StyledListItem>
        );
    }
    if (href) {
        return (
            <StyledListItem>
                <Link href={href} passHref legacyBehavior>
                    <StyledLinkContainer
                        {...(onClick && {
                            onClick: () => onClick(),
                        })}
                        data-testid={getTestId(`${itemTestId}-item`)}
                    >
                        <StyledSubMenuText $caseSensitive={caseSensitive}>
                            {text}
                        </StyledSubMenuText>
                    </StyledLinkContainer>
                </Link>
                {divider && <StyledHorizontalRule />}
            </StyledListItem>
        );
    }

    return (
        <StyledListItem>
            <StyledSubMenuText $caseSensitive={caseSensitive}>
                {text}
            </StyledSubMenuText>
            {divider && <StyledHorizontalRule />}
        </StyledListItem>
    );
}
