import {
    ITraceTelemetry,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';

import { type ErrorCodes, type ApplicationIdentifier } from '@tgg/common-types';

import { type FlagsConstants } from '../featureFlags';

export type TggLoggerMethods =
    | Exclude<
          Uncapitalize<keyof typeof SeverityLevel>,
          'verbose' | 'information' | 'warning'
      >
    | 'out'
    | 'info'
    | 'warn'
    | 'log'
    | 'debug';

export interface TggTrace extends ITraceTelemetry {
    severityLevel: SeverityLevel;
    correlationId: string;
    properties: {
        Application: string;
        UserAgent: string;
    };
}

export type InjectedTggTraceCorrelationParameters = {
    correlationId: string;
    resolvedPath: string;
    url: string;
    isMobileRequest: boolean;
    isMobileSession: boolean;
};

export type RequiredMetaTggTraceCorrelationParameters = {
    operation: string;
};

export type OptionalMetaTggTraceCorrelationParameters = Partial<{
    correlationId: string;
    paymentId: string;
    journeyInfo: {
        journeyName: string;
        journeyStep?: string;
    };
    statusCode: number | false;
    errorCode?: ErrorCodes;
}>;

export type MetaTggTraceCorrelationParameters =
    RequiredMetaTggTraceCorrelationParameters &
        OptionalMetaTggTraceCorrelationParameters;

export type TggTraceCorrelationParameters =
    InjectedTggTraceCorrelationParameters & MetaTggTraceCorrelationParameters;

export type TggLogger = {
    [key in TggLoggerMethods]: (
        message: string,
        parameters: TggTraceCorrelationParameters,
    ) => void;
};

export type LogMessageData = {
    message: string;
    applicationIdentifier: ApplicationIdentifier | FlagsConstants.UNRESOLVED;
    parameters: TggTraceCorrelationParameters;
    severityLevel: SeverityLevel | 'debug';
};

export enum LoggerConstants {
    UNRESOLVED = 'unresolved',
}
